import '../styles/ErrorPage.scss'
import App from '../components/App'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import { Link } from 'gatsby'
import { selectDeviceSize } from '../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const NotFoundPage = () => {
  const wWidth = useSelector(selectDeviceSize)
  return (
    <App>
      <SEO nofollow noindex title="Страница не найдена | Youtalk" />

      <Header />
      <section className="not-found">
        <div className="container">
          <div className="row">
            <div className="designer-col col-12">
              <div className="not-found__middle">
                <h1 className="not-found__title">
                  Такой страницы <br />
                  не существует
                </h1>
                <p className="not-found__desc">
                  Возможно, в ссылке допущена ошибка из-за опечатки или
                  раскладки клавиатуры. Или ссылка, по которой вы&nbsp;перешли,
                  устарела.
                </p>
                <Link className="button button_primary button_large" to="/">
                  Перейти на главную
                </Link>
              </div>
            </div>
          </div>
        </div>
        {wWidth > 767 && (
          <>
            <img
              alt=""
              className="not-found__blob not-found__blob_top"
              src="/img/icons/404/top.svg"
            />
            <img
              alt=""
              className="not-found__blob not-found__blob_bot"
              src="/img/icons/404/bot.svg"
            />
          </>
        )}
      </section>
    </App>
  )
}

export default NotFoundPage
